.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.dark-mode {
  background-color: #333;
  color: #fff;
}

.dark-mode .card {
  background-color: #444;
  color: #fff;
}

.dark-mode .form-control,
.dark-mode .form-select {
  background-color: #555;
  color: #fff;
  border-color: #666;
}

.dark-mode .btn-primary {
  background-color: #0056b3;
  border-color: #004085;
}

.dark-mode .btn-secondary {
  background-color: #5a6268;
  border-color: #545b62;
}

.dark-mode .alert-info {
  background-color: #1c4e63;
  color: #d1ecf1;
  border-color: #1c4e63;
}

.dark-mode .modal-content {
  background-color: #444;
  color: #fff;
}

.dark-mode .close {
  color: #fff;
}

.dark-mode .table {
  color: #fff;
}

.dark-mode .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

/* Overlay to dim the background */
.grocery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.grocery-modal-overlay.dark-mode {
  background-color: rgba(0, 0, 0, 0.9); /* Darker background for dark mode */
}

/* Modal content */
.grocery-modal-content {
  background-color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-y: auto;
  max-height: 80vh;
}

/* Dark mode for modal content */
.grocery-modal-content.dark-mode {
  background-color: #2c2c2c; /* Dark background for modal in dark mode */
  color: #fff; /* Light text color in dark mode */
}

/* Close button */
.grocery-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.grocery-modal-close:hover {
  color: red;
}

/* Table styles */
.grocery-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.grocery-table th,
.grocery-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Dark mode table header */
.grocery-table th {
  background-color: #f4f4f4; /* Light background for headers in light mode */
}

.grocery-table.dark-mode th {
  background-color: #444; /* Dark background for headers in dark mode */
}

/* Dark mode table body */
.grocery-table td {
  background-color: #fff;
}

.grocery-table.dark-mode td {
  background-color: #333; /* Dark background for table rows in dark mode */
  color: #ddd; /* Light text in dark mode */
}

/* Strikethrough for done items */
.grocery-table tr.done td {
  text-decoration: line-through;
  color: #888;
}

/* Input and button styles */
.grocery-form input,
.grocery-form button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.grocery-form button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.grocery-form button:hover {
  background-color: #0056b3;
}

/* Reset List button */
.reset-list {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.reset-list i {
  margin-right: 10px;
}

/* Dark mode styles for reset button */
.reset-list.dark-mode {
  color: #fff; /* Light text for reset button in dark mode */
}

.remove-item-btn {
  background: none;
  border: none;
  color: #d9534f; /* Red color for delete */
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .grocery-modal-content {
    width: 95%;
  }
}


